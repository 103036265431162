.custom-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 0px;
    /* Adjust the gap size between columns */
    row-gap: 30px;
    /* Adjust the gap size between rows */
}

.logo-container {
    width: 64px;
    /* Set the width */
    height: 64px;
    /* Set the height */
    background: white;
    /* Set the background color */
    border-radius: 8px;
    /* Adjust the border-radius for rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
    /* Optional: add a shadow */
}

.logo-image {
    width: 100%;
    /* Ensure the image fills the container */
    height: 100%;
    /* Ensure the image fills the container */
    object-fit: contain;
    /* Contain the image within the container */
    border-radius: 8px;
    /* Match the container's border-radius */
}

.logo-container-2{
    width: 72px;
    /* Set the width */
    height: 72px;
    /* Set the height */
    background: white;
    /* Set the background color */
    border-radius: 8px;
    /* Adjust the border-radius for rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional: add a shadow */
}

.logos-grid{
    max-width: 800px;
}

/* .icon-bar {
    display: flex;
    justify-content: center;
    align-items: left; 
    width: 100%; 
} */


.icon-bar {
    justify-content: flex-start;
    /* Aligns icons to the start of the flex container */
    margin-top: 32px;
    padding-left: 4px;
    margin-bottom: 10px;
    /* Adjust this value based on your actual layout needs to match the text start */
}
/* .icon {
    fill: none;
    stroke: currentColor; 
    stroke-width: 2px;
    margin: 0 10px; 
}

.icon-bar {
    display: flex;
    justify-content: space-around;
    width: 100%; 
    padding: 6px 0;
} */

